import * as React from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import { Col, Row } from 'react-bootstrap';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { mapEdgesToNodes } from '../utils';
import BlogPostGrid from '../components/BlogPostGrid';

interface Props {
  data: any;
}

// markup
const NotFoundPage = ({ data }: Props) => {
  const posts = mapEdgesToNodes(data.posts.edges);

  return (
    <Layout pageTitle="Home">
      <SEO title="Wehkamp Tech Blog" description="Wehkamp Tech Blog" />

      <section className="not-found">
        <Container>

          <Row className="justify-content-center">
            <Col md={10}>
              <h1 className="text-center">
                404: Page not found
                <small className="d-block">
                  The requested resource could not be found.
                  However, we have some interesting articles for you
                </small>
              </h1>

              <hr />
            </Col>
          </Row>

          <BlogPostGrid posts={posts} />
        </Container>
      </section>
    </Layout >
  );
};

export const query = graphql`
  query MyQuery {
    site {
      siteMetadata {
        title
      }
    }
    posts: allMdx(sort: {fields: [frontmatter___publishedDate], order: DESC}, limit: 3) {
      edges {
        node {
          ...BlogPostPreview
        }
      }
    }
  }
`;

export default NotFoundPage;
